// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import * as Tracing from "@sentry/tracing";
import { version } from "../package.json";

const integrations = [];

integrations.push(
  new Tracing.Integrations.BrowserTracing({
    tracingOrigins: [
      process.env.NEXT_PUBLIC_DABOUNCE_URL
        ? new URL(process.env.NEXT_PUBLIC_DABOUNCE_URL).host
        : "dabounce.app",
    ],
  })
);

Sentry.init({
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV,
  dsn: "https://9289bfad966845ab87e50e9c31c94ca5@o2125.ingest.sentry.io/5578175",
  release: version,
  tracesSampleRate: 1.0,
  integrations,
});
